.Unselectable {
  user-select: none;
}

.BarContainer {
  display: flex;
  width: 100%;
  height: 5px;
  flex-direction: row;
  background-color: #eeeeee;
}

.Bar {
  background-color: #1a90ff;
}