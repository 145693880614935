.AppContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #c0c0c0;
}

.AppSpacer {
  flex-grow: 1;
}

.App {
  display: flex;
  flex-direction: column;

  border-top: solid white 4px;
  border-left: solid white 4px;
  border-bottom: solid #808080 4px;
  border-right: solid #808080 4px;

  width: fit-content;
  background: #c0c0c0;
  padding: 8px;
}

.Top {
  display: flex;
  height: fit-content;
  flex-direction: row;
  border-top: solid #808080 4px;
  border-left: solid #808080 4px;
  border-bottom: solid white 4px;
  border-right: solid white 4px;
  background: #c0c0c0;
  padding: 11px;
  margin-bottom: 8px;
}

.Bottom {
  display: block;
  width: fit-content;
  height: fit-content;
  border-top: solid #808080 4px;
  border-left: solid #808080 4px;
  border-bottom: solid white 4px;
  border-right: solid white 4px;
  background: #c0c0c0;
}

.CenterBox {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
}
